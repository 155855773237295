
import fnApi from '/utils/common/methods'
import IndexImage from './components/IndexImage';
import IndexDiamond from './components/IndexDiamond';
import IndexFlashSale from './components/IndexFlashSale';
import IndexHot from './components/IndexHot';
import IndexProduct from './components/IndexProduct';
import IndexTitle from './components/IndexTitle';
import IndexSlider from './components/IndexSlider';

export default {
    name: 'Home-Section',
    layout: 'main-footer',
    components: {
        IndexImage,
        IndexDiamond,
        IndexFlashSale,
        IndexHot,
        IndexProduct,
        IndexTitle,
        IndexSlider
    },
    async asyncData({$api, cookies, ...context}) {
        const [seoR, listR, recR, categoryR] = await Promise.all([
            $api.home.getSeo(),
            $api.home.getCompo({
                searchRmd: false
            }),
            $api.common.getRecV5Common({
                index: 1,
                pageNum: 1,
                pageSize: 20,
            }, false),
            $api.category.getMenu()
        ])
        const seo = seoR?.result?.list || [];
        const list = listR?.result?.templateList || [];
        const listF = list.filter(item => {
            return ![6, 7, 8, 9].includes(item.type)
        })
        const rec = recR?.result || {}
        const categorySeo = categoryR?.result || []
        return {
            seo: seo[0] || {},
            list: listF,
            rec,
            categorySeo
        }
    },
    data() {
        return {
            skeleton: true,
            hasLogin: false,   // 是否登录
            allData: {},       // 所有数据
            fnApi: fnApi,      // 通用方法
            list: [],
            rec: {}
        }
    },
    head() {
        return this.setHead()
    },
    created() {
        this.initialSet()
        // this.getCompoAjax()
    },
    mounted() {
        this.skeleton = false
        this.initialMountedSet()
    },
    methods: {
        // 获取组件
        getCompoAjax() {
           this.$api.home.getCompo().then(response => {
                // type -> 1 图片组件 2 金刚区 3 闪购组件 4 热区组件 5 商品组件 6 社媒组件 7 组合商品展示 8 评论组件 9 视频组件
                const res = response.result || {};
                const list = res.templateList || [];
                this.allData = res || {}
                this.skeleton = false
                this.list = list.filter(item => {
                    return ![6, 7, 8, 9].includes(item.type)
                })
            })
        },
        // SEO HEAD
        setHead() {
            const ctoCountry = this.$cookies.get('country') || {};
            const ogImage = this.$ctxApi.toImgUrl(this.seo.ogImage)
            const head = this.$ctxApi.seoHead.initial({
                title: this.seo.titleMap||this.$translate('YFN: Free Shipping Time Limited Only'),
                keywords: this.seo.remarkMap||this.$translate('Shop YFN jewelry for you including necklaces, rings, bracelets, anklets, charms, earrings, body jewelry, at our online jewelry platform. Discover our new luxury jewelry collection with Free shipping plus a 60-day return guarantee!'),
                description: this.seo.remarkMap||this.$translate('Shop YFN jewelry for you including necklaces, rings, bracelets, anklets, charms, earrings, body jewelry, at our online jewelry platform. Discover our new luxury jewelry collection with Free shipping plus a 60-day return guarantee!'),
                image: ogImage || 'https://cdn2.selleroa.com/yfni/test/1677563532037fbab4e.jpg'
            });
            const script = [{
                type: 'application/ld+json',
                json: {
                    "@context": "https://schema.org",
                    "@type": "Organization",
                    "url": "https://www.yfn.com",
                    "logo": "https://cdn2.selleroa.com/yfn-upload/review/1693969711875.png"
                }
            }, {
                type: 'application/ld+json',
                json: {
                    "@context": "https://schema.org",
                    "@type": "WebSite",
                    "url": "https://www.yfn.com/",
                    "potentialAction": {
                        "@type": "SearchAction",
                        "target": {
                            "@type": "EntryPoint",
                            "urlTemplate": "https://www.yfn.com/search.htm?keyword={search_term_string}"
                        },
                        "query-input": "required name=search_term_string"
                    }
                }
            }];
            return {
                ...head,
                script: script,
            }
        },
        // 初始化
        initialSet() {
            this.hasLogin = this.$login()
        },
        initialMountedSet() {
            // this.$fnApi.buried.criteo({
            //     type: 'viewHome'
            // })
        }
    },
}
